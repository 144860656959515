<template>
    <span :class="statusClassName">
        {{fmtBillingInvoiceStatus(value)}}
    </span>
</template>

<script>
    import enumUtil from "@/assets/js/enums-util";

    export default {
        name: "BillingInvoiceStatus",
        mixins: [enumUtil],
        props: {
            value: {
                type: String,
                default: null
            }
        },
        computed: {
            statusClassName() {
                const className = 'pdt-billing-invoice-status';
                switch (this.value) {
                    case 'KJSB':
                    case 'ZFSB':
                    case 'CHSB':
                    case 'YCH':
                    case 'YZF':
                        return `${className}-warning`;
                    default:
                        return className;
                }
            }
        },
        methods: {
            fmtBillingInvoiceStatus(val) {
                return this.fmtEnum('InvoiceStatus', val);
            }
        }
    }
</script>

<style scoped>

</style>