<template>
    <span :class="statusClassName">
        {{FmtExpressStatus(value)}}
    </span>
</template>

<script>
    export default {
        name: "ExpressStatus",
        props: {
            value: {
                type: Boolean,
                default: null
            }
        },
        computed: {
            statusClassName() {
                const className = 'pdt-express-status';
                return `${className}-${this.value == null ? '' : (this.value ? '1' : '0')}`;
            }
        },
        methods: {
            FmtExpressStatus() {
                return (this.value == null ? '--' : (this.value ? '已邮寄' : '未邮寄'));
            }
        }
    }
</script>

<style scoped>

</style>