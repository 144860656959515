import store from "@/store/index";
import {FmtEnum} from "../../util/enumUtil";

export default {
    methods: {
        /**
         * 格式化枚举
         * @param name 枚举
         * @param value 枚举值
         * @returns {*} 返回枚举名
         */
        fmtEnum: (name, value) => {
            // console.log("Enums:", store.state.enums);
            return FmtEnum(store.state.enums[name], value);
        }
    }
};