<template>
    <el-select v-model="model.expressPosted" :placeholder="allLabel">
        <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
        </el-option>
    </el-select>
</template>

<script>
    export default {
        name: "ExpressStatusSelect",
        props: {
            model: {
                type: Object,
                default: () => {
                    return {expressPosted: 0};
                }
            },
            isAddAll: {
                type: Boolean,
                default: true
            },
            allLabel: {
                type: String,
                default: '全部'
            }
        },
        data() {
            return {
                options: [
                    {value: 0, label: '未邮寄'},
                    {value: 1, label: '已邮寄'}
                ]
            };
        },
        created() {
            if (this.isAddAll) {
                this.options.splice(0, 0, {value: -1, label: this.allLabel});
            }
        },
        methods: {}
    }
</script>

<style scoped>

</style>